<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>


<v-row justify="center">
    <v-dialog
     v-model="dialog"
     max-width="600px"
   >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
          <v-row style="margin-top:4px">
            <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <input
                  label="Nom *"
                  required
                  v-model=device.name
                  v-on:keyup="validateName()"
                  placeholder="Nom *"
                  class="form-control"
                  :style= "validate_name ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'"
                >
                <small v-if="!validate_name" style="color:#ff7370;font-style:italic">{{ validate_name_error_message }}</small>
                
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <select
                  v-model=device.store_id
                  class="form-control"
                ><option value="" selected>-- Magasin --</option><option v-for="store in stores" v-bind:key="store.id" v-bind:value="store.id">{{store.name}}</option></select>
              </v-col>
          </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog
     v-model="dialog_renew"
     scrollable
     max-width="600px"
   >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container style="text-align:center">
                  <table class="table table-striped">
                    <tbody> 
                        <tr><th class="text-left">Nom</th><td class="text-right">{{ device.name }}</td></tr>
                        <tr><th class="text-left">Magasin</th><td class="text-right">{{ device.store_name }}</td></tr>
                        <tr><th class="text-left">Serial Number</th><td class="text-right">{{ device.serial_number }}</td></tr>
                        <tr><th class="text-left">Due à cette date</th><td class="text-right">{{ device.next_renewal_date }}</td></tr>
                        <tr><th class="text-left">Montant</th><td class="text-right">{{ device.amount }} HTG</td></tr>
                        <tr><th class="text-left">Statut</th><td class="text-right" v-if=device.status><span class="badge badge-success">Actif</span></td><td class="text-right" v-if=!device.status><span class="badge badge-danger">Annulé</span></td></tr>
                    </tbody>
                  </table>
                  <button v-if=!onLoad class="btn" @click="loader = 'loading';createPayment(device)" style="background:#e4002b;color:white;font-size:16px;;margin-bottom:30px;padding:right:50px;padding-left:20px;text-align:center"><img :src="require('@/assets/moncash.png')" style="width:50px"> <span style="margin-right:10px;margin-left:10px">|</span> Renouveler</button>

                  <p style="padding:10px;border-radius:3px;background:#f2dede;text-align:left">
                    Utilisez le bouton ci-dessus afin de renouveler votre abonnement pour 1 mois. Dans le cas ou vous voudriez effectuer un renouvellement pour plus d’un mois, utilisez les moyens de paiements suivants :<br>
                    <br><strong style="text-decoration:underline">Virement bancaire</strong><br><br>
                    <ul>
                      <li><strong>Banque : </strong>Sogebank</li>
                      <li><strong>Numéro du Compte : </strong>470-600-7038</li>
                      <li><strong>Nom du Compte : </strong>WYZDEV S.A.</li>
                      <li><strong>Type : </strong>Courant</li>
                      <li><strong>Devise : </strong>HTG</li>
                      <li><strong>Mémo : </strong>{{ business.name }} - {{ device.store_name }} - {{ device.serial_number }}</li>
                      <li><strong>Assurez-vous de payer le montant exact, soit {{ device.amount }} HTG, et utilisez le mémo auto-généré ci-dessus.</strong></li>
                    </ul>
                    <strong style="text-decoration:underline">Paiement MonCash Manuel</strong><br><br>
                    <ul>
                      <li><strong>Numéro : </strong>+509-39101010</li>
                      <li><strong>Assurez-vous de payer le montant exact, soit {{ device.amount }} HTG.</strong></li>
                      <li><strong>Contactez-nous au moment du paiement afin que notre équipe puisse activer votre appareil muni du numéro du compte MonCash utilisé pour effectuer le paiement.</strong></li>
                    </ul>
                    <strong style="text-decoration:underline">Paiement à la Centrale</strong><br><br>
                    <ul>
                      <li><strong>Présentez vous à notre Centrale pour effectuer votre paiement au #7 Route de Kenscoff.</strong></li>
                    </ul>
                    Pour toute autres questions, contactez-nous au <strong>+509-39101010</strong>.

                  </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close_renew"
            :loading="loading"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
  <template v-slot:item="{ item }">
    <tr :class="getBackgroundColor(item.next_renewal_date)">
      <td class="text-left">{{ item.name }}</td>
      <td class="text-left">{{ item.serial_number }}</td>
      <td class="text-left">{{ item.manufacturer }}</td>
      <td class="text-left">{{ item.model }}</td>
      <td class="text-left" v-if='getBackgroundColor(item.next_renewal_date) == "background_red"'><span class="badge badge-danger">{{ item.next_renewal_date }}</span></td>
      <td class="text-left" v-if='getBackgroundColor(item.next_renewal_date) == "background_yellow"'><span class="badge badge-warning" style="color:white">{{ item.next_renewal_date }}</span></td>
      <td class="text-left" v-if='getBackgroundColor(item.next_renewal_date) == "background_none"'>{{ item.next_renewal_date }}</td>
      <td class="text-left" v-if='item.status == 1'><span class="badge badge-success">Actif</span></td>
      <td class="text-left" v-if='item.status == 0'><span class="badge badge-danger">Inactif</span></td>
      <td class="text-right"><v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        class="mr-2 renew_button"
        @click="renew(item)"
      >
        mdi-currency-usd
      </v-icon></td>
    </tr>
  </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import moment from 'moment'

export default {
  name: 'SubscriptionsView',
  methods: {
    load: async function(){
      this.business = JSON.parse(localStorage.business)
      if(this.$route.query.transaction_id != undefined){
        this.verifyPayment(this.$route.query.transaction_id)
      }
      this.authentication = JSON.parse(localStorage.authentication)
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      this.$axios.get('/devices?include=business,store&business_id='+this.business_id)
      .then(function (response) {
        self.rows = response.data
        self.enable = true          
      })
    },
    validateName:function(){
      if(this.device.name == ''){
        this.validate_name = false
        this.validate_name_error_message = "Le nom ne peut pas être vide."
        return false
      }else{
        this.validate_name = true
        return true
      }
    },
    getBackgroundColor: function(next_renewal_date){
      var next_renewal = moment(next_renewal_date)
      var now_minus_five_days = moment().subtract(5, 'd')
      var now = moment()
      if(next_renewal.isBefore(now)){
        if(next_renewal.isAfter(now_minus_five_days)){
          return 'background_yellow'
        }else{
          return 'background_red'
        }
      }else{
        return 'background_none'
      }
      // return 'background_none'
    },
    createPayment: function(device){
      var self = this
      this.loading = true
      var url = '/renewals/pay'
      var data = { amount: device.amount, device_id: device.id, serial_number: device.serial_number, payment_method: 1, period: 1 }
      this.$axios.post(url, data)
      .then(response => {
        window.open(response.data.message);
        self.dialog = false
        self.loading = false
        self.reset()
      })
    },
    verifyPayment: function(transaction_id){
      // var self = this
      console.log(transaction_id)
      var url = '/renewals/verify'
      var data = { transaction_id: transaction_id }
      this.$axios.post(url, data)
      .then(response => {
        console.log(response.data)
      })
    },
    getStatusColor: function(status){
      var color = "class_red"
      if(status){
        color = "class_green"
      }
      return color
    },
    getStores: function(){
      var self = this;
      this.$axios.get('/stores?business_id='+this.business_id)
      .then(function (response) {
        self.stores = response.data
      })
    },
    getBusiness: function(){
      var self = this;
      this.$axios.get('/businesses?uid='+this.authentication.schema)
        .then(function (response) {
          self.business_id = response.data.id
        })
    },
    getStatusLabel: function(status){
      var label = "INACTIF"
      if(status){
        label = "ACTIF"
      }
      return label
    },
    getAmount: function(amount){
      return amount+ ' HTG'
    },
    save: function(){
      var self = this
      console.log(this.device)
      if(this.validateName() && this.device.store_id != ''){
        this.loading = true
        var url = ''
        if (this.index > -1) { 
          url = '/devices/'+self.device.id
          this.$axios.put(url+"?include=business,store&data="+encodeURIComponent(JSON.stringify(self.device)))
          .then(response => {
            Object.assign(self.rows[self.index], response.data)
            self.dialog = false
            self.loading = false
            self.reset()
          })
        }
      }else{
        alert("Vérifiez les données du formulaire puis réessayez.")
      }
    },
    edit: function(item){
      this.index = this.rows.indexOf(item)
      this.device = Object.assign({}, item)
      console.log(this.device)
      this.form_title = "Modifier l'Appareil : "+this.device.name
      this.dialog = true
    },
    renew: function(item){
      this.index = this.rows.indexOf(item)
      this.device = Object.assign({}, item)
      this.device.store_name = item.store.name
      this.form_title = "Renouvelez votre Abonnement : "+this.device.name
      this.dialog_renew = true
    },
    close: function(){
      this.dialog = false
      this.reset()
      this.loading = false
    },
    close_renew: function(){
      this.dialog_renew = false
      this.reset()
      this.loading = false
    },
    reset: function(){
      this.device = {
        name: '', 
        manufacturer: '', 
        model: '', 
        serial_number: '',
        amount:0,
        next_renewal_date:(new Date(Date.now())).toISOString().substr(0, 10),
      }
      this.validate_name = true
      this.index = -1
      this.form_title = 'New Subscription'
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.reset()
      })
    },
    total() {
      var total = 0
      for (var i = this.rows.length - 1; i >= 0; i--) {
        if(this.rows[i].status == 1){
          total = total + parseInt(this.rows[i]['amount'])
        }
      }
      return total
    }
  },
  mounted(){

    this.load()
    this.getStores()
  },
  data: () => ({
      menu: false,
      modal: false,
      authentication: [],
      min_price: [{name: "Actif", id: true}, {name: 'Inactif', id: false}],
      menu2: false,
      search: '',
      index: -1,
      onLoad: false,
      validate_name: true,
      validate_name_error_message: 'Le nom ne peut pas être vide.',
      loading: false,
      business_id: 0,
      form_title: "New Subscription",
      items: [
        {
          text: 'APPAREILS',
          disabled: false,
          href: '/subscriptions',
        }
      ],
      fluid: true,
      enable: false,
      business: [],
      headers: [
          {
            text: 'NOM',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'SN', value: 'serial_number' },
          { text: 'FOURNISSEUR', value: 'manufacturer' },
          { text: 'MODELE', value: 'model' },
          { text: 'RENOUVELLEMENT', value: 'next_renewal_date' },
          { text: 'STATUT', value: 'status' },
          { text: 'ACTIONS', value: 'actions' },
        ],
        rows: [],
        businesses: [],
        stores: [],
        dialog:'',
        dialog_renew: '',
        dialogDelete: false,
        device: {
          name: '', 
          manufacturer: '', 
          model: '', 
          serial_number: '',
          amount:0,
          next_renewal_date:(new Date(Date.now())).toISOString().substr(0, 10),
        }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#003F74 !important;
  }

  .settingscontainer{
    margin-top:25px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003F74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .renew_button{
    font-size: 16px;
    padding: 5px;
    background: orange !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:-8px;
  }
   .v-application .v-dialog .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:0px!important;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .new_button{
    margin-top:-7px!important;
  }

  .color-red{
    color:red!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }

.v-progress-circular{
    margin-top:40px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }

    .background_red{
      background:#f2dede;
    }
    .background_yellow{
      background:#fcf8e3;
    }
</style>